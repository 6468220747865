<template>
    <div>
        <button class="button is-danger is-rounded has-margin-auto" @click="exportToPDF">
            {{ i18n('Export to PDF') }}
        </button>
        <div id="extract">
            <div class="user-info">
                <div class="user-name">
                    {{ user.person.name }}
                </div>
                <div class="role-name">
                    {{ user.role.name }}
                </div>
                <div class="nin">
                    {{ user.person.nin }}
                </div>
            </div>
            <h3 class="title is-3 user-info" v-if="user.role.id === 3">
                {{ i18n('Your employees') }}
            </h3>
            <enso-table class="box is-paddingless raises-on-hover" id="extracts"/>
            <div class="points-info">
                <h3 class="title is-3 user-info">
                    {{ i18n('Points') }}
                </h3>
                <div class="points-container">
                    <table class="points-table">
                        <thead>
                        <tr>
                            <th>{{ i18n('Accumulated') }}</th>
                            <th>{{ i18n('Redeemed') }}</th>
                            <th>{{ i18n('Available') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ accumulated }}</td>
                            <td>{{ spent }}</td>
                            <td>{{ point.total }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EnsoTable } from '@enso-ui/bulma';
import { mapState } from 'vuex';
import html2pdf from 'html2pdf.js';

export default {
    name: 'Index',

    components: { EnsoTable },

    inject: ['http', 'route', 'i18n'],

    data() {
        return {
            point: {
                total: 0,
            },
            accumulated: 0,
            spent: 0,
        };
    },

    computed: {
        ...mapState(['user']),
    },
    mounted() {
        this.fetchPoints();
    },

    methods: {
        fetchPoints() {
            this.http
                .get(this.route('point.userPoints', { user_id: this.user.id }))
                .then(({ data }) => {
                    this.point = data.point;
                    this.accumulated = data.accumulated;
                    this.spent = data.spent;
                })
                .catch(this.errorHandler);
        },
        exportToPDF() {
            html2pdf(document.getElementById('extract'), {
                margin: 0,
                filename: `${this.user.person.name}_extract.pdf`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.user-info {
    background-color: $lilac;
    padding: .6rem;
    margin-bottom: .7rem;
    text-align: center;
    font-size: 1.1rem;
    color: $secondary;
}

.user-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: $secondary;
}

.role-name {
    font-size: 20px;
    font-weight: bold;
    color: $secondary;
    margin-bottom: 10px;
}

.nin {
    font-size: 18px;
    color: #777;
    margin-bottom: 10px;
}

.points-info {
    margin-top: 20px;
    padding: 10px;
}

.points-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.points-table {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.points-table th, .points-table td {
    padding: 15px;
}

.points-table th {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 10px 10px 0 0;
}

.points-table td {
    background-color: #f0f0f0;
    color: #333;
    border: none;
}
</style>
